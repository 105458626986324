import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  TRANSPARENT, LARGE, CONTENT_DETAIL, LC_WHITE, LC_BLACK, SINGLE_COL,
} from '@constants';
import {
  Layout, BasicDarkHero, ContentLayout, BasicRichText,
} from '@molecules';
import { TakeActionSection } from '@organisms';
import { setSearchParams, gtmEvent, getKeyValue } from '@utils';

const ContentDetailPageTemplate = ({ location, data, pageContext }) => {
  const {
    site, page, settings, languageLabels,
  } = data;

  const { locale } = pageContext;
  const { mobileDisclaimer, donationDisclaimer, siteTitle } = settings;
  const { social } = getKeyValue(site, 'siteMetadata') || {};
  const {
    metaTitle, metaDescription, metaImage, path, pageContent, disableAlertBar,
    enLanguagePageLinks, pageInOtherLanguages, disableDonationBar,
  } = page || {};
  const {
    title: pageTitle, attribution, releaseDate, heroImage, introContent,
    content, actionCardSectionTitle, actionCardsSection, triggerGtmEvent,
  } = pageContent;
  const { url: metaImageUrl } = getKeyValue(metaImage, 'file') || {};

  const noImage = !heroImage || !heroImage.fluid;

  useEffect(() => {
    if (triggerGtmEvent) {
      const params = location.search && new URLSearchParams(location.search);
      const val = params ? params.get('amount') : 0;
      const value = parseFloat(val).toFixed(2);
      gtmEvent('scd-pageview', { value });
    }
  }, [location.search, pageContent, triggerGtmEvent]);

  const searchParams = setSearchParams(location);

  return (
    <Layout
      location={location}
      metaDescription={metaDescription && metaDescription.metaDescription}
      metaUrl={`https://${process.env.GATSBY_HOST_DOMAIN}${path}`}
      metaTitle={`${metaTitle} | ${siteTitle}`}
      metaImageUrl={metaImageUrl}
      fbAppID={social.fbAppID}
      twitter={social.twitter}
      locale={locale}
      navTheme={TRANSPARENT}
      settings={settings}
      navAnimate
      pageInOtherLanguages={pageInOtherLanguages}
      enLanguagePageLinks={enLanguagePageLinks}
      languageLabels={languageLabels}
      disableDonationBar={disableDonationBar}
      disableAlertBar={disableAlertBar}
      searchParams={searchParams}
    >
      <BasicDarkHero
        title={pageTitle}
        byline={attribution}
        releaseDate={releaseDate}
        heroImage={heroImage}
      />
      <ContentLayout
        layout={`${SINGLE_COL}${noImage ? 'noimage' : ''}`}
        hasBottomSection={!!actionCardSectionTitle}
      >
        {introContent && (
          <BasicRichText
            content={introContent}
            size={LARGE}
            textWidth={SINGLE_COL}
            color={LC_BLACK}
            location={location}
          />
        )}
        {content && (
          <BasicRichText
            content={content}
            textWidth={SINGLE_COL}
            color={LC_BLACK}
            pageType={CONTENT_DETAIL}
            mobileDisclaimer={mobileDisclaimer}
            donationDisclaimer={donationDisclaimer}
          />
        )}
      </ContentLayout>
      {actionCardSectionTitle && actionCardsSection && (
        <TakeActionSection
          sectionTitle={actionCardSectionTitle}
          contentReferences={actionCardsSection}
          sectionTheme={LC_WHITE}
          pageType={CONTENT_DETAIL}
        />
      )}
    </Layout>
  );
};

export default ContentDetailPageTemplate;

export const contentDetailPageQuery = graphql`
  query ContentDetailPageBySlug($slug: String!, $locale: String!) {
    site {
      ...SiteMetadata
    }
    page: contentfulPage(path: { eq: $slug }, language: { eq: $locale }) {
      ...PageMetadata
      pageContent {
        ... on ContentfulPageContentContentDetail {
          title
          id
          triggerGtmEvent
          actionCardSectionTitle
          actionCardsSection {
            externalLink
            title
            internalLink {
              ... on ContentfulPage {
                id
                path
              }
            }
            id
          }
          attribution
          releaseDate(formatString: "MMM D, YYYY")
          heroImage {
            fluid(quality: 90, maxWidth: 700) {
              ...GatsbyContentfulFluid_withWebp
            }
            file {
              url
            }
          }
          introContent {
            raw
            references {
              ... on ContentfulPage {
                contentful_id
                path
              }
              ... on ContentfulRedirect {
                contentful_id
                from
                to
              }
              ... on ContentfulAsset {
                contentful_id
                __typename
                file {
                  url
                }
                title
              }
            }
          }
          content {
            raw
            references {
              ... on ContentfulPage {
                contentful_id
                path
              }
              ... on ContentfulRedirect {
                contentful_id
                from
                to
              }
              ... on ContentfulComponentDonationAsk {
                contentful_id
                id
                internal {
                  type
                }
                desktopDonationButtons {
                  key
                  id
                  value
                }
                mobileDonationButtons {
                  key
                  id
                  value
                }
                donationLink
                header
              }
              ... on ContentfulComponentImageCollage {
                contentful_id
                id
                internal {
                  type
                }
                imageLayout
                images {
                  fluid(quality: 90, maxWidth: 700) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                  description
                  contentful_id
                }
                caption {
                  raw
                }
              }
              ... on ContentfulAsset {
                contentful_id
                __typename
                file {
                  url
                }
                title
              }
              ... on ContentfulComponentMediaEmbed {
                contentful_id
                id
                internal {
                  type
                }
                mediaTitle
                mediaType
                mediaUid
                imageOrCustomThumbnail {
                  fluid(quality: 90, maxWidth: 1170) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
                mediaDescription {
                  raw
                }
              }
              ... on ContentfulComponentForm {
                contentful_id
                id
                internal {
                  type
                }
                formId
                defaultFormSourcing
                formType
                submitButtonLabel
                postSubmitMessage {
                  raw
                }
                postSubmitRedirect
                postSubmitDonationAsk {
                  id
                  header
                  intro {
                    raw
                  }
                  desktopDonationButtons {
                    key
                    id
                    value
                  }
                  mobileDonationButtons {
                    key
                    id
                    value
                  }
                  donationLink
                  header
                }
                contentfulfields {
                  label
                  name
                  required
                  id
                  type
                  desktopWidth
                  mobileWidth
                  selectradiocheckboxOptions {
                    key
                    value
                  }
                }
                submitButtonDesktopWidth
                submitButtonMobileWidth
              }
            }
          }
        }
      }
    }
    settings: contentfulGlobalSiteSettings(language: { eq: $locale }) {
      ...GlobalSiteSettings
    }
    languageLabels: contentfulGlobalSiteSettings(language: { eq: "en-US" }) {
      ...LanguageLabels
    }
  }
`;
